import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Field} from '@app/interfaces';
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {UserApiService} from "@app/services/api/user-api.service";
import {TaxNumberEnum} from "@app/enums/TaxNumberEnum";

@Component({
  selector: 'app-form-input-tax-number',
  templateUrl: './form-input-tax-number.component.html',
  styleUrls: ['./form-input-tax-number.component.scss']
})
export class FormInputTaxNumberComponent implements OnInit, OnDestroy {
  @Input() control: FormControl;

  @Input() formGroup: FormGroup;

  @Input() field: Field<string>;

  private destroy$ = new Subject<void>();

  prefix = '';
  taxSubjectKey = TaxNumberEnum.IS_TAX_PAYER;
  private isTaxSubject: boolean = false;

  private prefixValidator = Validators.pattern(/^[A-Z]{2}/);

  constructor(private cdr: ChangeDetectorRef, private userService: UserApiService) {
  }

  ngOnInit() {
    this.control.addValidators(Validators.pattern(/^[A-Z]{0,2}[0-9]+$/));

    this.userService.getUserCountryCode()
      .pipe(takeUntil(this.destroy$))
      .subscribe((countryCode) => {
        this.prefix = countryCode.data.toUpperCase();

        this.control.removeValidators(this.prefixValidator);
        this.prefixValidator = Validators.pattern(new RegExp(`^${this.prefix}`));
      });

    this.control.statusChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.cdr.detectChanges();
      });

    this.taxSubjectKey = this.formGroup.controls[TaxNumberEnum.IS_TAX_PAYER] ? TaxNumberEnum.IS_TAX_PAYER : TaxNumberEnum.TAX_SUBJECT;

    this.formGroup.controls[this.taxSubjectKey].valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((isTaxPayer: boolean) => {
        this.setIsTaxSubject(isTaxPayer);
      });

    this.formGroup.controls[TaxNumberEnum.TAX_NUMBER]?.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((newValue: string) => {
        if (this.isTaxSubject && !newValue.startsWith(this.prefix)) {
          this.control.setValue(this.prefix);
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  setIsTaxSubject(isTaxSubject) {
    this.isTaxSubject = isTaxSubject;

    if (isTaxSubject) {
      this.control.addValidators(this.prefixValidator);
    } else {
      this.control.removeValidators(this.prefixValidator);
    }

    if (isTaxSubject) {
      const currentValue = this.control.value || '';

      if (!currentValue.startsWith(this.prefix)) {
        this.control.setValue(this.prefix + currentValue);
      }
    } else if (this.control.value?.startsWith(this.prefix)) {
      this.control.setValue(this.control.value.substring(this.prefix.length));
    }
  }
}
