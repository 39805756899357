import {Injectable} from '@angular/core';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {DefaultsEnum} from "@app/interfaces/defaults.enum";

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private static SHOW_TIME = 3500;

  constructor(private snackBar: MatSnackBar) {
  }

  showErrorAlert(errorText?: string, error?: Error) {
    console.error(errorText, error);

    this.snackBar.open(errorText ?? $localize`:@@notification.default-error-message:Prišlo je do napake. Prosim poskusite znova`,
      null,
      {
        duration: DefaultsEnum.LONG_TOAST_DURATON,
        panelClass: ['bg-orange', 'text-white']
      });
  }

  showInfoAlert(message: string) {
    this.snackBar.open(message, null, {
      duration: NotificationService.SHOW_TIME,
      panelClass: ['bg-primary', 'text-white']
    });
  }

  showSuccessAlert(customMessage?: string, config?: { duration?: number }) {
    this.snackBar.open(customMessage ?? $localize`:@@notification.success:Uspešno opravljeno`, null, {
      duration: NotificationService.SHOW_TIME,
      panelClass: ['bg-green', 'text-white'],
      ...config
    });
  }

  showUpdateSuccessfulAlert() {
    this.showSuccessAlert($localize`:@@notification.update-success:Podatki so bili uspešno posodobljeni`);
  }

  showUpdateFailedAlert() {
    this.showErrorAlert($localize`:@@notification.update-failed:Podatki niso bili uspešno posodobljeni`);
  }

  showApplicationSuccessfulAlert() {
    this.showSuccessAlert($localize`:@@notification.application-success:Vloga je bila uspešno oddana`);
  }

  /** @deprecated Use showErrorAlert instead */
  showErrorAlertWithCustomText(text: string) {
    this.showErrorAlert(text);
  }
}
