import {Injectable} from '@angular/core';
import {Form} from '../interfaces/entities/form';
import {SectionItem} from '../interfaces/form/section-item';
import {FieldItem} from '../interfaces/form/field-item';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Field} from '../interfaces';
import {FieldSection} from "@app/interfaces/fields/field.section";

@Injectable({
  providedIn: 'root'
})
export class FormHelperService {

  constructor(private formBuilder: FormBuilder) {
  }

  toTabbedForm(form: Form): FormGroup {
    const groups = {};

    form.layouts.forEach((sectionItem: SectionItem, i) => groups[i] = this.extractFieldsPerTabs(sectionItem));
    for (const group in groups) {
      if (groups.hasOwnProperty(group)) {
        const controls = {};

        for (const fieldItem of groups[group]) {
          const field = form.fields[(fieldItem as FieldItem).fieldUuid];
          controls[(fieldItem as FieldItem).fieldUuid] = this.prepareFormGroupControlForFieldItem(field);
        }

        groups[group.toString()] = this.formBuilder.group(controls);
      }
    }

    return this.formBuilder.group(groups);
  }

  toTabbedFormInline(sections: FieldSection[]): FormGroup {
    const controls = {};

    for (const section of sections) {
      for (const field of section.items) {
        controls[field.fieldUuid] = this.prepareFormGroupControlForFieldItem(field);
      }
    }

    return this.formBuilder.group(controls);
  }

  public prepareFormGroupControlForFieldItem(field: Field<any>) {

    if (field.type === 'multi_checkbox') {
      return this.buildCheckboxes(field, this.formBuilder.group({}));
    } else if (field.type === 'repeater') {
      return this.buildRepeater(field, this.formBuilder.array([]));
    }

    const validators = this.fillValidators(field);
    return this.formBuilder.control(field.value, validators);
  }

  private buildCheckboxes(currentValue: Field<any>, control: FormGroup) {
    const sections = {};

    for (const section of currentValue.config.sections) {
      const controls = {};

      for (const item of section.items) {
        if (currentValue.value && currentValue.value[section.section_uuid]) {
          controls[Object.keys(item)[0]] = this.formBuilder.control(currentValue.value[section.section_uuid][Object.keys(item)[0]]);
        } else {
          controls[Object.keys(item)[0]] = this.formBuilder.control(false);
        }
      }

      sections[section.section_uuid] = this.formBuilder.group(controls);
    }

    for (const section of Object.keys(sections)) {
      control.addControl(section, sections[section]);
    }

    return control;
  }

  private extractFieldsPerTabs(sectionItem: SectionItem) {
    let fields = [];

    for (const item of sectionItem.items) {
      if (item.type === 'field') {
        fields.push(item);
      } else {
        fields = fields.concat(this.extractFieldsPerTabs(item as SectionItem));
      }
    }

    return fields;
  }

  private fillValidators(field: Field<any>) {
    const validators = [];

    if (field.validators?.includes('required')) {
      validators.push(Validators.required);
    }

    if (['number', 'currency', 'mileage', 'repeater', 'percent'].indexOf(field.type) !== -1) {
      const min = field.validators?.find((item) => item.includes('min:'));
      const max = field.validators?.find((item) => item.includes('max:'));

      if (min) {
        validators.push(Validators.min(parseFloat(min.substr(4))));
      }

      if (max) {
        validators.push(Validators.max(parseFloat(max.substr(4))));
      }
    }

    if (['files', 'photos', 'public_photos', 'nlb_files', 'proxy_photos'].indexOf(field.type) !== -1) {
      const min = field.validators?.find((item) => item.includes('min:'));
      const max = field.validators?.find((item) => item.includes('max:'));

      if (min) {
        validators.push(Validators.minLength(parseFloat(min.substr(4))));
        validators.push(Validators.required);
      }

      if (max) {
        validators.push(Validators.maxLength(parseFloat(max.substr(4))));
      }
    }

    if (field.config['regex']) {
      validators.push(Validators.pattern(field.config.regex));
    }

    return validators;
  }

  getValuesFromTabbedForm(formGroup: FormGroup): { [key: string]: any } {
    const tabs = Object.keys(formGroup.getRawValue());
    return tabs.reduce((values, tab) => ({...values, ...formGroup.getRawValue()[tab]}), {});
  }

  findControl(customId: any, control: any) {
    let parent = control;

    while (parent.parent) {
      parent = parent.parent;
    }

    return this.findControlInControls(customId, parent.controls);
  }

  private findControlInControls(customId: string, controls) {
    const keys = Object.keys(controls);

    if (keys.indexOf(customId) !== -1) {
      return controls[customId];
    }

    for (const key of keys) {

      const control = controls[key];

      if (control.controls) {

        const result = this.findControlInControls(customId, control.controls);

        if (result) {

          return result;
        }
      }
    }

    return null;
  }

  public buildRepeater(field: Field<any>, formArray: FormArray) {
    if (!field.value) {
      return formArray;
    }

    for (const wrappedItem of field.value) {
      const repeaterItem = this.prepareEmptyRepeaterItem(field);
      repeaterItem.controls.wrappedItemUuid.setValue(wrappedItem.wrappedItemUuid);

      for (const fieldItem of wrappedItem.value) {
        (repeaterItem.controls.value as FormGroup).controls[fieldItem.fieldUuid].setValue(fieldItem.value);
      }

      formArray.push(repeaterItem);
    }

    const validators = [];

    if (field.config?.min) {
      validators.push(Validators.minLength(field.config.min));
    }

    if (field.config?.isRequired) {
      validators.push(Validators.required);
    }

    if (field.config?.max) {
      validators.push(Validators.maxLength(field.config.max));
    }

    formArray.setValidators(validators);

    return formArray;
  }

  public prepareEmptyRepeaterItem(field: Field<any>): FormGroup {
    const controls = {};

    for (const fieldUuid in field.config.subFields) {
      if (field.config.subFields.hasOwnProperty(fieldUuid)) {
        controls[fieldUuid] = this.prepareFormGroupControlForFieldItem(field.config.subFields[fieldUuid]);
      }
    }

    return this.formBuilder.group({
      wrappedItemUuid: this.formBuilder.control(null),
      value: this.formBuilder.group(controls)
    });
  }

  modifyOnSetFields(value: any, field: Field<any>, formGroup: FormGroup, booleanValue = false) {
    if (!value || !field.config.hasOwnProperty('onSelect')) {
      return;
    }

    const selectedTypeId = booleanValue ? value : Object.keys(value)[0];
    const fieldsToHide = [...(field.config.onSelect[selectedTypeId]?.hideFields || []), ...(field.config.onSelect._default?.hideFields || [])];
    const fieldsToShow = [...(field.config.onSelect[selectedTypeId]?.showFields || []), ...(field.config.onSelect._default?.showFields || [])];
    const fieldsToRequire = [...(field.config.onSelect[selectedTypeId]?.requiredFields || []), ...(field.config.onSelect._default?.requiredFields || [])];
    const fieldsToNotRequire = [...(field.config.onSelect[selectedTypeId]?.notRequiredFields || []), ...(field.config.onSelect._default?.notRequiredFields || [])];

    for (const fieldValue of fieldsToHide) {
      if (formGroup.controls[fieldValue]) {
        // This is done because the abstractControl doesn't have a hidden attribute
        // @ts-ignore
        formGroup.controls[fieldValue].hidden = true;
        formGroup.controls[fieldValue].disable();
      } else {
        console.error("The field " + fieldValue + " doesn't exists.");
      }
    }

    for (const fieldValue of fieldsToShow) {
      if (formGroup.controls[fieldValue]) {
        // This is a hack because the abstractControl doesn't have a hidden attribute
        // @ts-ignore
        formGroup.controls[fieldValue].hidden = false;
        formGroup.controls[fieldValue].enable();
      } else {
        console.error("The field " + fieldValue + " doesn't exists.");
      }
    }

    for (const fieldValue of fieldsToRequire) {
      if (formGroup.controls[fieldValue]) {
        // This is a hack because the abstractControl doesn't have a hidden attribute
        // @ts-ignore
        formGroup.controls[fieldValue].setValidators([Validators.required]);
        formGroup.controls[fieldValue].updateValueAndValidity({emitEvent: true});
      } else {
        console.error("The field " + fieldValue + " doesn't exists.");
      }
    }

    for (const fieldValue of fieldsToNotRequire) {
      if (formGroup.controls[fieldValue]) {
        // This is a hack because the abstractControl doesn't have a hidden attribute
        // @ts-ignore
        formGroup.controls[fieldValue].removeValidators([Validators.required]);
        formGroup.controls[fieldValue].updateValueAndValidity({emitEvent: true});
      } else {
        console.error("The field " + fieldValue + " doesn't exists.");
      }
    }
  }
}
