import {PublicAccount, PublicVehicle} from '../interfaces';
import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {VirtualSalonApiService} from "@app/services/api/virtual-salon-api.service";

@Injectable({providedIn: 'root'})
export class PublicVehicleListingResolver  {
  constructor(private virtualSalonApiService: VirtualSalonApiService) {
  }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    return this.virtualSalonApiService.getPublishedVehicles()
      .pipe(
        map((result) => result.data),
        catchError((error, observable) => of(null)));
  }
}
