import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl} from '@angular/forms';
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'app-field-errors',
  templateUrl: './field-errors.component.html',
  styleUrls: ['./field-errors.component.scss']
})
export class FieldErrorsComponent implements OnInit, OnDestroy {

  @Input() control: AbstractControl;

  @Input() hasToBeTouched: boolean = true;

  private destroy$ = new Subject<void>();

  constructor(private cdr: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.control.statusChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.cdr.detectChanges();
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

}
