import {ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {FormControl} from "@angular/forms";
import {Field} from "@app/interfaces";
import {Upload} from "@app/interfaces/entities/upload";
import {Subscription} from "rxjs";
import {UploadApiService} from "@app/services/api/upload-api.service";
import {NotificationService} from "@app/services/notification.service";
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import {NgxDropzoneChangeEvent} from "ngx-dropzone";

@Component({
  selector: 'app-form-input-invoice-files',
  templateUrl: './form-input-invoice-files.component.html',
  styleUrls: ['./form-input-invoice-files.component.scss']
})
export class FormInputInvoiceFilesComponent implements OnInit, OnDestroy, OnChanges {
  @Input() control: FormControl;

  @Input() field: Field<Upload[]>;

  public file: TempFile | null = null;
  isDisabled = false;

  private statusSubscription: Subscription;

  constructor(private uploadApiService: UploadApiService,
              private notificationService: NotificationService,
              private matDialog: MatDialog,
              private ref: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.statusSubscription = this.control.statusChanges.subscribe(
      (status) => (this.isDisabled = status === 'DISABLED')
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.control && changes.control.currentValue) {
      if (changes.control.currentValue.value) {
        this.file = {upload: changes.control.currentValue.value[0]};
      }
    }
  }

  ngOnDestroy(): void {
    this.statusSubscription.unsubscribe();
  }

  upload(fileToBeUploaded: File) {
    this.uploadApiService.uploadFile(fileToBeUploaded)
      .subscribe((result) => {
        this.file = {upload: result.data};
        this.updateControlValue();
        this.ref.markForCheck();
      },
      (error) => {
        this.notificationService.showErrorAlert();
        this.file = null;
      }
    );
  }

  onSelect($event: NgxDropzoneChangeEvent) {
    if ($event.addedFiles && $event.addedFiles.length > 0) {
      const addedFile = $event.addedFiles[0];
      this.file = null;
      this.upload(addedFile);
    }
  }

  removeFile() {
    this.file = null;
    this.updateControlValue();
    this.ref.markForCheck();
  }

  private updateControlValue() {
    if (this.file?.upload) {
      this.control.setValue([this.file.upload]);
    } else {
      this.control.setValue([]);
    }
  }
}

interface TempFile {
  upload?: Upload;
}
