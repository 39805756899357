import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import {Observable, of} from 'rxjs';
import {AuthService} from '@app/services/auth.service';
import {catchError, map} from "rxjs/operators";
import {BusinessUnitsService} from "@app/services/accounts/business-units.service";

@Injectable({
    providedIn: 'root'
})
export class AuthGuard  {

    constructor(private authService: AuthService,
                private router: Router,
                private businessUnitsService: BusinessUnitsService) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.handle();
    }

    canLoad(
        route: Route,
        segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
        return this.handle();
    }

    private handle() {
        if (this.authService.isLoggedIn()) {

            // This load accountConfig and prepared storage for BU
            return this.businessUnitsService.onPageLoad().pipe(
                map((config) => {
                    return true;
                }),
                catchError(() => {
                    return of(false);
                }));
        }

        this.redirectToLogin();
        return false;
    }

    private redirectToLogin() {
        this.router.navigate(['/login']);
    }
}
