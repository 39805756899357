import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges, OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {Field} from '../../../../interfaces';
import {Upload} from '../../../../interfaces/entities/upload';
import {ConfirmDialogComponent, DialogType} from '../../../dialogs';
import {UploadApiService} from '../../../../services/api/upload-api.service';
import {NotificationService} from '../../../../services/notification.service';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {Subscription} from 'rxjs';
import {NgxDropzoneChangeEvent} from 'ngx-dropzone';
import {PhotoUtilService} from "../../../../services/util/photo-util.service";
import {CdkDragDrop, moveItemInArray, transferArrayItem} from "@angular/cdk/drag-drop";
import {isMobile} from "@app/util/browser.helper";
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'app-form-input-photos',
  templateUrl: './form-input-photos.component.html',
  styleUrls: ['./form-input-photos.component.scss'],
})
export class FormInputPhotosComponent implements OnInit, OnDestroy, OnChanges {
  @Input() control: FormGroup;

  @Input() field: Field<Upload[]>;

  public images: Image[] = [];
  public fileArray = [];
  isDisabled = false;
  public isMobile = isMobile();

  private statusSubscription: Subscription;

  constructor(private uploadApiService: UploadApiService,
              private notificationService: NotificationService,
              private matDialog: MatDialog,
              private photoUtil: PhotoUtilService,
              private ref: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.statusSubscription = this.control.statusChanges.subscribe((status) => this.isDisabled = status === 'DISABLED');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.control.currentValue) {
      if (changes.control.currentValue.value) {
        this.images = changes.control.currentValue.value.map((currentUpload) => {
          return {upload: currentUpload};
        });
      }
    }
  }

  ngOnDestroy(): void {
    this.statusSubscription.unsubscribe();
  }

  keys(item: {}) {
    return Object.keys(item);
  }

  upload(fileToBeUploaded: File, temporaryFile: { upload?: Upload }) {
    this.photoUtil.resizeImage(fileToBeUploaded).subscribe((resizedImage) => {
      this.uploadApiService.uploadImageFile(resizedImage)
        .subscribe((result) => {
            setTimeout(() => {
              temporaryFile.upload = result.data;
              this.updateControlValue();
              this.ref.detectChanges();
            }, 0);
          },
          (error) => {
            setTimeout(() => {
              this.notificationService.showErrorAlert();
              this.images.splice(this.images.indexOf(temporaryFile), 1);
              this.ref.detectChanges();
            }, 0);
          });
    });
  }

  removeFiles() {
    const dialogRef = ConfirmDialogComponent.open(this.matDialog, DialogType.DELETE_PHOTO, true);

    dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          for (const item of this.fileArray) {
            this.images.splice(this.images.indexOf(item), 1);
          }
          this.fileArray = [];
          this.updateControlValue();
          this.ref.markForCheck();
        }
      }
    );
  }

  onItemSelectChange(item: any) {
    if (this.checkIfItemSelected(item) === -1) {
      this.fileArray.push(item);
    } else {
      const index = this.fileArray.indexOf(item);
      this.fileArray.splice(index, 1);
    }
  }

  checkIfItemSelected(item) {
    return this.fileArray.indexOf(item);
  }

  removeFile(item: any) {
    const dialogRef = ConfirmDialogComponent.open(this.matDialog, DialogType.DELETE_PHOTO, true);

    dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.images.splice(this.images.indexOf(item), 1);
          this.updateControlValue();
          this.ref.markForCheck();
        }
      }
    );
  }

  private updateControlValue() {
    this.control.setValue(this.images.filter((item) => item.upload).map((item) => item.upload));
  }

  moveBack(item: Image) {
    const index = this.images.indexOf(item);
    this.move(index, index + 1);
  }

  moveForward(item: Image) {
    const index = this.images.indexOf(item);
    this.move(index, index - 1);
  }

  private move(from, to) {
    this.images.splice(to, 0, this.images.splice(from, 1)[0]);
    this.updateControlValue();
    this.ref.markForCheck();
  }

  onSelect($event: NgxDropzoneChangeEvent) {
    if ($event.addedFiles) {
      $event.addedFiles.forEach((addedFile) => {
        const tempFile = {upload: null};
        this.images = [...this.images, tempFile];

        this.upload(addedFile, tempFile);
      });
    }
  }

  onDrop(event: CdkDragDrop<any[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(this.images, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
    }
    this.updateControlValue();
  }
}

interface Image {
  upload?: Upload;
}
