<div class="relative">
  <input type="text"
         [formControl]="control"
         [attr.aria-label]="field.config.label"
         [ngClass]="{ 'input-error': control.invalid }"
         placeholder="{{ field.config.placeholder }}"
         autocomplete="off"
         [attr.data-field]="field?.fieldUuid"
         [attr.data-typeId]="field?.config?.typeId">
</div>
