import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl, FormGroup} from "@angular/forms";
import {Field} from "../../../../interfaces";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {FormHelperService} from "@app/util/form-helper.service";

@Component({
    selector: 'app-form-input-boolean',
    templateUrl: './form-input-boolean.component.html',
    styleUrls: ['./form-input-boolean.component.scss']
})
export class FormInputBooleanComponent implements OnInit, OnDestroy {

    @Input() control: AbstractControl;
    @Input() formGroup: FormGroup;

    @Input() hideLabel = false;

    @Input() field: Field<string>;

    private destroy$ = new Subject<void>();

    constructor(private cdr: ChangeDetectorRef, private formHelperService: FormHelperService) {
    }

    ngOnInit() {
        this.control.statusChanges
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                this.cdr.detectChanges();
            });

        this.control.valueChanges
          .pipe(takeUntil(this.destroy$))
          .subscribe((value) => {
            const val = value ? "true" : "false";
            this.formHelperService.modifyOnSetFields(val, this.field, this.formGroup, true);
          });

        this.formHelperService.modifyOnSetFields(this.control.value, this.field, this.formGroup, true);

        if (!this.control.value && this.field.config.default) {
            this.control.setValue(this.field.config.default);
        }
    }

    ngOnDestroy() {
        this.destroy$.next();
    }
}
