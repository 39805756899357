import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import {UserService} from '@app/services/userService/user.service';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PermissionGuard  {

  constructor(
    private userService: UserService,
    private router: Router
  ) {

  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    return this.userService.getUserProfile().pipe(map((userProfile) => {
      const requiredPermissionsForRoute = route.data['permissions'] as Array<string>;

      const result = requiredPermissionsForRoute.some(permission => userProfile.permissions[permission] === true);

      if (result) {
        return true;
      } else {
        return this.router.parseUrl('/403');
      }
    }));
  }
}
