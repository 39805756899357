import {Inject, Injectable, LOCALE_ID} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {environment} from '@src/environments/environment';
import {Field, PaginatedResult, PublicAccount, PublicVehicle, SingleResult, Vehicle, VehicleData, WorkflowExcerpt} from '@app/interfaces';
import {tap} from 'rxjs/operators';
import {DOCUMENT} from '@angular/common';
import {Form} from '@app/interfaces/entities/form';
import {ArrayResult} from '@app/interfaces/responses/array-result';
import {ImportedVehicle} from '@app/interfaces/integration/imported-vehicle';
import {LocaleEnum} from '@app/enums/LocaleEnum';
import {PaginationFilter} from '@app/interfaces/pagination/paginationFilter';
import {ApiHelperService} from '@app/util/api-helper.service';
import {AdvertisingForm} from '@app/interfaces/entities/advertising-form';

@Injectable({
  providedIn: 'root'
})
export class VehicleApiService {

  private vehicleRefreshStream: Subject<Vehicle> = new BehaviorSubject(null);

  constructor(private http: HttpClient,
              @Inject(DOCUMENT) private document: Document,
              @Inject(LOCALE_ID) private locale: string,
  ) {
  }

  getVehicleRefreshStream() {
    return this.vehicleRefreshStream;
  }

  getPaginatedVehicles(paginationFilter: PaginationFilter):
    Observable<PaginatedResult<Vehicle[]>> {
    const params = ApiHelperService.buildPaginationParams(paginationFilter);

    return this.http.get<PaginatedResult<Vehicle[]>>(`${environment.apiUrl}/api/vehicles`, {params});
  }

  getPaginatedImportedVehicles(paginationFilter: PaginationFilter):
    Observable<PaginatedResult<ImportedVehicle[]>> {
    const params = ApiHelperService.buildPaginationParams(paginationFilter);

    return this.http.get<PaginatedResult<ImportedVehicle[]>>(`${environment.apiUrl}/api/integrations/vehicle-import/list`, {params});
  }

  getPaginatedSoldVehicles(paginationFilter: PaginationFilter):
    Observable<PaginatedResult<Vehicle[]>> {
    const params = ApiHelperService.buildPaginationParams(paginationFilter);

    return this.http.get<PaginatedResult<Vehicle[]>>(`${environment.apiUrl}/api/sold-vehicles`, {params});
  }

  getPaginatedVehiclesAds(paginationFilter: PaginationFilter):
    Observable<PaginatedResult<Vehicle[]>> {
    const params = ApiHelperService.buildPaginationParams(paginationFilter);

    return this.http.get<PaginatedResult<Vehicle[]>>(`${environment.apiUrl}/api/vehicles/ads`, {params});
  }

  getVehicle(vehicleUuid: string): Observable<SingleResult<Vehicle>> {
    return this.http.get<SingleResult<Vehicle>>(`${environment.apiUrl}/api/vehicles/${vehicleUuid}`);
  }

  getVehicleData(vehicleUuid: string): Observable<SingleResult<VehicleData>> {
    return this.http.get<SingleResult<VehicleData>>(`${environment.apiUrl}/api/vehicles/${vehicleUuid}/data`);
  }

  getForm(vehicleUuid?: string): Observable<SingleResult<Form>> {

    let params = new HttpParams();
    params = params.append('type', 'vehicle');
    params = params.append('vehicleUuid', vehicleUuid);

    return this.http.get<SingleResult<Form>>(`${environment.apiUrl}/api/forms`, {params});
  }

  getQuickForm(vehicleUuid?: string): Observable<SingleResult<Form>> {
    let params = new HttpParams();
    params = params.append('type', 'vehicle');
    params = params.append('formType', 'quick');
    params = params.append('vehicleUuid', vehicleUuid);

    return this.http.get<SingleResult<Form>>(`${environment.apiUrl}/api/forms`, {params});
  }

  getPublishForm(vehicleUuid: string): Observable<SingleResult<AdvertisingForm>> {
    return this.http.get<SingleResult<AdvertisingForm>>(`${environment.apiUrl}/api/vehicles/${vehicleUuid}/publish-form`);
  }

  store(fields: { [key: string]: any }, formType: string): Observable<SingleResult<Vehicle>> {
    let params = new HttpParams();
    switch (formType) {
      case 'quick': {
        params = params.append('formType', 'quick');
        return this.http.post<SingleResult<Vehicle>>(`${environment.apiUrl}/api/vehicles`, {...fields}, {params});
      }
      default: {
        return this.http.post<SingleResult<Vehicle>>(`${environment.apiUrl}/api/vehicles`, {...fields});
      }
    }
  }

  update(entityUuid: string, fields: { [key: string]: any }): Observable<SingleResult<Vehicle>> {
    return this.http.put<SingleResult<Vehicle>>(`${environment.apiUrl}/api/vehicles/${entityUuid}`, {...fields});
  }

  archive(entityUuid: string) {
    return this.http.post<SingleResult<any[]>>(`${environment.apiUrl}/api/vehicles/${entityUuid}/archive`, {});
  }

  setAsSold(entityUuid: string) {
    return this.http.post<SingleResult<any[]>>(`${environment.apiUrl}/api/vehicles/${entityUuid}/set-as-sold`, {});
  }

  setAsInStock(entityUuid: string) {
    return this.http.post<SingleResult<any[]>>(`${environment.apiUrl}/api/vehicles/${entityUuid}/set-as-in-stock`, {});
  }

  setAsReserved(entityUuid: string) {
    return this.http.post<SingleResult<any[]>>(`${environment.apiUrl}/api/vehicles/${entityUuid}/set-as-reserved`, {});
  }

  unarchive(entityUuid: string) {
    return this.http.post<SingleResult<any[]>>(`${environment.apiUrl}/api/vehicles/${entityUuid}/unarchive`, {});
  }

  delete(vehicleUuid: string) {
    return this.http.delete(`${environment.apiUrl}/api/vehicles/${vehicleUuid}`);
  }

  getFieldsForVehicles(entityUuid: string): Observable<SingleResult<Form>> {
    return this.http.get<SingleResult<Form>>(`${environment.apiUrl}/api/vehicles/${entityUuid}/form`);
  }

  updateStatus(vehicleUuid: string, statusMachineUuid: string, statusUuid: string): Observable<SingleResult<Vehicle>> {
    return this.http.put<SingleResult<Vehicle>>(`${environment.apiUrl}/api/vehicles/${vehicleUuid}/status`, {
      statusMachineUuid,
      statusUuid
    });
  }

  updateEntityGroups(vehicleUuid: string, entityGroups: string[]): Observable<SingleResult<Vehicle>> {
    return this.http.put<SingleResult<Vehicle>>(`${environment.apiUrl}/api/vehicles/${vehicleUuid}/entity-groups`, {
      entityGroups
    });
  }

  publish(vehicleUuid: string, fields: { [key: string]: any }): Observable<SingleResult<Vehicle>> {
    return this.http.post<SingleResult<Vehicle>>(`${environment.apiUrl}/api/vehicles/${vehicleUuid}/publish`, {...fields});
  }

  updatePublish(vehicleUuid: string, fields: { [key: string]: any }): Observable<SingleResult<Vehicle>> {
    return this.http.put<SingleResult<Vehicle>>(`${environment.apiUrl}/api/vehicles/${vehicleUuid}/publish`, {...fields});
  }

  unpublish(vehicleUuid: string): Observable<SingleResult<Vehicle>> {
    return this.http.post<SingleResult<Vehicle>>(`${environment.apiUrl}/api/vehicles/${vehicleUuid}/unpublish`, {});
  }

  getMarketAnalysis(vehicleUuid: string) {
    return this.http.get(`${environment.apiUrl}/api/market-study/summary`, {params: {vehicleUuid}});
  }

  patchField(vehicleUuid: string, fieldUuid: string, value: any): Observable<SingleResult<Vehicle>> {
    return this.http.patch<SingleResult<Vehicle>>(`${environment.apiUrl}/api/vehicles/${vehicleUuid}/fields`, {
      fields: [{
        fieldUuid,
        value
      }]
    }).pipe(tap((result) => {
      this.vehicleRefreshStream.next(result.data);
    }));
  }

  patchFields(vehicleUuid: string, fields): Observable<SingleResult<Vehicle>> {
    return this.http.patch<SingleResult<Vehicle>>(`${environment.apiUrl}/api/vehicles/${vehicleUuid}/fields`, {
      fields
    }).pipe(tap((result) => {
      this.vehicleRefreshStream.next(result.data);
    }));
  }

  updateEntityCategory(vehicleUuid: string, entityCategoryUuid: string) {
    return this.http.put<SingleResult<Vehicle>>(`${environment.apiUrl}/api/vehicles/${vehicleUuid}/entity-category`, {
      entityCategoryUuid
    });
  }

  getField(vehicleUuid: string, fieldUuid: string): Observable<SingleResult<Field<any>>> {
    return this.http.get<SingleResult<Field<any>>>(`${environment.apiUrl}/api/vehicles/${vehicleUuid}/fields/${fieldUuid}`);
  }

  getFields(vehicleUuid: string, typeIds: string[]): Observable<ArrayResult<Field<any>>> {
    return this.http.post<ArrayResult<Field<any>>>(`${environment.apiUrl}/api/vehicles/${vehicleUuid}/fields`, {fields: typeIds});
  }

  getPublicUrlForVehicle(vehicleUuid: string, locale: string) {
    return `${environment.apiUrl}/${locale}/public/vehicles/${vehicleUuid}`;
  }

  getPublicUrlForVehicleV2(vehicleUuid: string, locale: string) {
    return `${environment.apiUrl}/${locale}/public/v2/virtual-salon/vehicles/${vehicleUuid}`;
  }

  extractPublicVehicleName(vehicle: PublicVehicle) {
    if (vehicle && vehicle.vehicle) {
      return `${vehicle.vehicle.brand} ${vehicle.vehicle.name}`;
    }

    return '-';
  }

  extractPublicVehicleVersion(vehicle: PublicVehicle) {
    if (vehicle?.vehicle?.version) {
      return `${vehicle.vehicle.version} ${vehicle.vehicle.engine}`;
    }

    return '-';
  }

  extractVehicleDescription(vehicle: Vehicle | ImportedVehicle) {
    const descriptions = [];

    if (vehicle.vehicle) {
      descriptions.push(vehicle.vehicle.brand);
    }

    if (vehicle.modelYear) {
      descriptions.push(vehicle.modelYear);
    }

    if (vehicle.customVehicleType) {
      descriptions.push(vehicle.customVehicleType);
    }

    if (vehicle.vehicle?.version) {
      descriptions.push(vehicle.vehicle.version);
    }

    return descriptions.join(', ');
  }

  getVehicleWorkflows(entityUuid: string): Observable<ArrayResult<WorkflowExcerpt>> {
    return this.http.get<ArrayResult<WorkflowExcerpt>>(`${environment.apiUrl}/api/vehicles/${entityUuid}/workflows`);
  }

  getMappedVehicles(vehicleUuids: any[]) {
    let params = new HttpParams();

    for (const vehicleUuid of vehicleUuids) {
      params = params.append('vehicleUuids[]', vehicleUuid);
    }

    return this.http.get<SingleResult<any>>(`${environment.apiUrl}/api/helpers/vehicles/map`, {params});
  }

  getVehicleInvoices(vehicleUuid: string, sort: { column: string, order: string }, filter: {}, allowFalsy = false): Observable<PaginatedResult<any>> {
    let params = new HttpParams();

    if (sort.column) {
      params = params.append('sortBy', sort.column.toString());

      if (sort.order) {
        params = params.append('sortOrder', sort.order.toString());
      }
    }

    const filters = [];

    for (const prop in filter) {
      if (filter.hasOwnProperty(prop)) {
        if (allowFalsy || filter[prop]) {
          filters.push({key: prop, value: filter[prop]});
        }
      }
    }

    if (filters.length) {
      filters.forEach((item) => {
        params = params.append('filter[]', `${item.key}:${item.value}`);
      });
    }

    return this.http.get<PaginatedResult<any>>(`${environment.apiUrl}/api/vehicles/${vehicleUuid}/invoices`, {params});
  }

  getRVCPrint(vehicleUuid: string) {
    return this.http.post(
      `${environment.apiUrl}/api/vehicles/${vehicleUuid}/pdf/rvc`, {}, {responseType: 'blob' as 'json'}
    );
  }

  /** @deprecated - use projectors */
  handleInvoiceActions(actions: { [key: string]: { [key: string]: unknown } }, currencyId: string) {
    return this.http.post(
      `${environment.apiUrl}/api/vehicles/invoice-actions`,
      {actions, currencyId},
    );
  }

  getPreviousVehicles(vehicleUuid: string) {
    return this.http.post(
      `${environment.apiUrl}/api/vehicles/${vehicleUuid}/previousVehicles`,
      {},
    );
  }

  getVehicleFromVin(vehicleVin: string): Observable<SingleResult<Vehicle>> {
    return this.http.get<SingleResult<Vehicle>>(`${environment.apiUrl}/api/vehicles/vin/${vehicleVin}`);
  }

  removeAds(vehicleUuid: string) {
    return this.http.post(`${environment.apiUrl}/api/vehicles/remove-ads`, {vehicleUuid});
  }

  public exportVehicleStock(filter: { q: string; entityGroupUuids: any[]; showAll: boolean; statusUuid: string; commissionSale: string; businessUnitUuid: string; entityCategoryUuid: string; leaseBack: string, date: string }) {
    return this.http.post(`${environment.apiUrl}/api/vehicles/export/stock`, filter, {responseType: 'blob' as 'json'});
  }

  public linkVehicleToInvoice(vehicleUuid: string, documentId: string) {
    return this.http.post<{success: boolean}>(`${environment.apiUrl}/api/vehicles/${vehicleUuid}/link-to-invoice`, {documentId});
  }
}

