import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {AutocompleteValuesApiService} from "@app/services/api/autocomplete-values-api.service";
import {finalize, takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {AutocompleteValues, TestPlatesValue, TransformedTestPlatesOptions} from "@app/interfaces/entities/autocomplete-values";
import {SingleResult} from "@app/interfaces";

const listKey = 'test-plates';

@Component({
  selector: 'app-test-plates-dialog',
  templateUrl: './test-plates-dialog.component.html',
  styleUrls: ['./test-plates-dialog.component.scss']
})
export class TestPlatesDialogComponent implements OnInit, OnDestroy {

  protected isLoading: boolean = true;
  protected isSaving: boolean = false;
  protected options: AutocompleteValues<TestPlatesValue>[] = [];
  private fieldConfig;
  private destroy$ = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<any>,
    private autocompleteValuesApiService: AutocompleteValuesApiService,
  ) {
    this.fieldConfig = data.fieldConfig;
  }

  ngOnInit(): void {
    this.autocompleteValuesApiService.getOptions(listKey)
      .pipe(takeUntil(this.destroy$))
      .subscribe((result: SingleResult<AutocompleteValues[]>) => {
        this.options = result.data;
        this.isLoading = false;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  save() {
    this.isSaving = true;

    this.autocompleteValuesApiService.saveOptions(this.options)
      .subscribe(() => {
        this.isSaving = false;
      }, () => {
        this.isSaving = false;
      });
  }

  removeOption(option: AutocompleteValues) {
    this.options.splice(this.options.indexOf(option), 1);
  }

  addOption() {
    this.options.push({
      list_key: this.fieldConfig.listKey ?? listKey,
      value: {
        value: '',
        license: ''
      },
    });
  }

  close() {
    const transformedOptions: TransformedTestPlatesOptions[] = this.transformOptions(this.options);
    this.dialogRef.close(transformedOptions);
  }

  transformOptions(options: AutocompleteValues<TestPlatesValue>[]): TransformedTestPlatesOptions[] {
    return options.map((option: AutocompleteValues<TestPlatesValue>) => {
      return {
        list_key: option.list_key,
        value: option.value.value,
        license: option.value.license,
      };
    });
  }

  areOptionsValid(): boolean {
    return this.options.every((option: AutocompleteValues<TestPlatesValue>) => {
      return option.value.value.length > 0 && option.value.license.length > 0;
    })
  }

  static open(dialog: MatDialog, fieldConfig) {
    return dialog.open(TestPlatesDialogComponent, {
      width: '700px',
      disableClose: false,
      data: {
        fieldConfig,
      }
    });
  }
}
